<template>
  <base-card class="pb-2 rounded-lg">
    <v-tabs v-model="tab" show-arrows class="rounded-t-lg">
      <v-tab
        v-for="tab in loading ? [] : tabsFiltered"
        class="rounded-t-lg"
        :key="tab.component"
      >
        <v-icon left small>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in loading ? [] : tabsFiltered" :key="item.name">
        <component :is="item.component" class="px-4 pb-4 pt-3" />
      </v-tab-item>
    </v-tabs-items>
  </base-card>
</template>

<script>
import PosManagement from "@/components/admin/pos/PosManagement.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo() {
    return { title: `POS` };
  },
  data: () => ({
    loading: true,
    tab: null,
    tabs: [
      {
        id: "management",
        name: "Equipamentos",
        component: "PosManagement",
        permission: 268435456,
        icon: "mdi-printer-pos",
      },
      // {
      //   id: "members",
      //   name: "Relatórios",
      //   component: "",
      //   permission: 536870912,
      //   icon: "mdi-account-group",
      // },
    ],
  }),
  methods: {
    ...mapActions("organization", ["updateMemberships"]),
    async getPos() {
      try {
        this.loading = true;
        this.selectTab();
      } catch (e) {
        this.error =
          e.message || "Ocorreu um erro ao buscar os membros filiados.";
      } finally {
        this.loading = false;
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    tabsFiltered() {
      return this.tabs.filter(
        (tab) => !tab.permission || this.hasPermission(tab.permission)
      );
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getMembership();
    },
    "$route.hash"() {
      this.selectTab();
    },
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
  },

  mounted() {
    this.getPos();
  },
  components: {
    PosManagement
  },
};
</script>

<style></style>
